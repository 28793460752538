import {
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HIW: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const navigate = useNavigate();

  const Info = ({
    title,
    subtitle,
    icon,
  }: {
    title: string;
    subtitle: JSX.Element;
    icon: string;
  }) => (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Typography variant="h3">{icon}</Typography>
      <Stack direction={'column'} width={'100%'}>
        <Typography variant="body1" fontWeight={600}>
          {title}
        </Typography>
        {subtitle}
      </Stack>
    </Stack>
  );

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  return (
    <>
      <Typography
        variant={mobile ? 'h5' : 'h4'}
        textAlign={'center'}
        sx={{ fontWeight: 700, mt: 4 }}
      >
        A Modern Real Estate Platform That Works for You
      </Typography>
      <Typography variant="subtitle1" textAlign={'center'} mt={1}>
        Homepoint combines expert agent support with tools that simplify the
        process and help you save more.
      </Typography>
      <br />
      <Grid2 container spacing={2} textAlign={'center'} p={2}>
        <Grid2 xs={12} md={4}>
          <Info
            title="Online Platform"
            subtitle={
              <Typography variant="subtitle1">
                Manage every aspect of your transaction in one place.
              </Typography>
            }
            icon="💻"
          />
        </Grid2>
        {!mobile && (
          <Grid2 xs={12} md={4}>
            <Info
              title="Professional Photos & Marketing"
              subtitle={
                <Typography variant="subtitle1">
                  Present your home beautifully to attract buyers.
                </Typography>
              }
              icon="📸"
            />
          </Grid2>
        )}
        {!mobile && (
          <Grid2 xs={12} md={4}>
            <Info
              title="Smart Electronic Lockbox"
              subtitle={
                <Typography variant="subtitle1">
                  Simplify showings without hassle. Approve anywhere.
                </Typography>
              }
              icon="🔑"
            />
          </Grid2>
        )}
        <Grid2 xs={12} md={4}>
          <Info
            title="Expert Support on Your Terms"
            subtitle={
              <Typography variant="subtitle1">
                Call or chat with our team when you need it.
              </Typography>
            }
            icon="👩‍💼"
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Info
            title="Flat Fee Cost"
            subtitle={
              <Typography variant="subtitle1">
                No percentage fees—<b>just $2,500, paid at closing.</b>
              </Typography>
            }
            icon="💰"
          />
        </Grid2>
        {!mobile && (
          <Grid2 xs={12} md={4}>
            <Info
              title="Dedicated Transaction Support"
              // eslint-disable-next-line max-len
              subtitle={
                <Typography variant="subtitle1">
                  Full coordination with title, mortgage, and inspection teams.
                </Typography>
              }
              icon="📋"
            />
          </Grid2>
        )}
        <Grid2 container spacing={2} textAlign={'left'} mt={mobile ? 0 : 4}>
          <Grid2 xs={12} md={6}>
            <Typography variant="h6" fontWeight={600} pt={2}>
              For Sellers
            </Typography>
            <Stack direction={'column'} spacing={1}>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">📸</Typography>
                <Typography variant="body1">
                  MLS listing with professional photos and floorplans.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">📣</Typography>
                <Typography variant="body1">
                  Full marketing, including yard sign and social media ads.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🔒</Typography>
                <Typography variant="body1">
                  Electronic lockbox for easy showings.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">📅</Typography>
                <Typography variant="body1">
                  Manage showings, offers, and communication in one place.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">👩‍💼</Typography>
                <Typography variant="body1">
                  Expert agent support always available.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🎉</Typography>
                <Typography variant="body1">And more!</Typography>
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <img
              src="/demo_1.png"
              alt="Seller Dashboard"
              width={'100%'}
              style={{
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick('/demo_1.png')}
            />
          </Grid2>
          {!mobile && (
            <Grid2 xs={12} md={6} justifyContent={'center'}>
              <img
                src="/demo_transactions.png"
                alt="Transaction Dashboard"
                width={mobile ? '100%' : '95%'}
                style={{
                  boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
                  borderRadius: '12px',
                  cursor: 'pointer',
                }}
                onClick={() => handleImageClick('/demo_transactions.png')}
              />
            </Grid2>
          )}
          <Grid2 xs={12} md={6}>
            <Typography variant="h6" fontWeight={600}>
              For Buyers
            </Typography>
            <Stack direction={'column'} spacing={1}>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🔍</Typography>
                <Typography variant="body1">
                  Flexible, no commitment buying process.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">📅</Typography>
                <Typography variant="body1">
                  Schedule home tours anytime.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">💰</Typography>
                <Typography variant="body1">
                  Guided offer preparation with expert review.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">💻</Typography>
                <Typography variant="body1">
                  Transaction tracking via a convenient online dashboard.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🔧</Typography>
                <Typography variant="body1">
                  Schedule inspections and coordinate vendors directly through
                  the platform.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🎉</Typography>
                <Typography variant="body1">And more!</Typography>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
        {mobile && (
          <Grid2 xs={12} md={6} justifyContent={'center'}>
            <img
              src="/demo_transactions.png"
              alt="Transaction Dashboard"
              width={mobile ? '100%' : '95%'}
              style={{
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick('/demo_transactions.png')}
            />
          </Grid2>
        )}
        <Grid2 xs={12} mt={2}>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ maxWidth: 300, mt: 2, fontWeight: 700 }}
              onClick={() => navigate('/product')}
            >
              View More Features
            </Button>
          </Stack>
        </Grid2>
      </Grid2>

      <Dialog
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        maxWidth="lg"
        onClick={() => setSelectedImage(null)}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </Dialog>
    </>
  );
};

export default HIW;
