import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { listings } from '@prisma/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import listingsService from '../../api/services/listings';

interface UpdateDetailsModalProps {
  listing: listings;
}

const UpdateDetailsModal = NiceModal.create(
  ({ listing }: UpdateDetailsModalProps) => {
    const modal = useModal();

    const formik = useFormik({
      initialValues: {
        description: listing.description ?? '',
        price: listing.price ?? 0,
      },
      validationSchema: Yup.object({
        description: Yup.string().required('Description is required'),
        price: Yup.number()
          .required('Price is required')
          .min(0, 'Price must be greater than 0'),
      }),
      onSubmit: async (values) => {
        try {
          await listingsService.patch({
            id: listing.id,
            data: {
              description: values.description,
              price: values.price,
            },
            notify: true,
          });
          modal.hide();
        } catch (error) {
          console.error(error);
        }
      },
    });

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        aria-labelledby="update-listing-modal"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Update Listing Details</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <TextField
                fullWidth
                id="price"
                name="price"
                label="Price"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.price}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => modal.hide()} variant="outlined" color="error">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default UpdateDetailsModal;
