import { HomeOutlined, ListAlt } from '@mui/icons-material';
import { IRoute } from '../interfaces';
import Listings from '../pages/Listings/Listings';
import FAQ from '../pages/Landings/components/FAQ';
import About from '../pages/Landings/About';
import NAR from '../pages/Landings/Blog/NAR';
import Policies from '../pages/Landings/Policies';
import Press from '../pages/Landings/Press';
import Product from '../pages/Landings/Product';
import BuyingPower from '../pages/Landings/Blog/BuyingPower';
import NotFound from '../pages/NotFound';
import Vendors from '../pages/Landings/Vendors';
import LandingExperiment from '../pages/Landings/LandingExperiment';
import AgentLandingV2 from '../pages/Landings/Agents/AgentLandingV2';

export const routes: Array<IRoute> = [
  {
    key: 'main-landing-route',
    title: 'Homepoint - Real Estate Your Way',
    path: '/',
    enabled: true,
    component: LandingExperiment,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'agents-landing-route',
    title: 'Homepoint - Modern Real Estate Platform',
    path: '/agents',
    enabled: true,
    component: AgentLandingV2,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'listings-route',
    title: 'Listing',
    path: '/listings',
    enabled: true,
    component: Listings,
    icon: ListAlt,
    menu: false,
    protected: false,
  },
  {
    key: 'faqs-route',
    title: 'FAQs',
    path: '/faqs',
    enabled: true,
    component: FAQ,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'about-route',
    title: 'About Us',
    path: '/aboutus',
    enabled: true,
    component: About,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'policies-route',
    title: 'Policies',
    path: '/policies',
    enabled: true,
    component: Policies,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'press-route',
    title: 'Press',
    path: '/press',
    enabled: true,
    component: Press,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'product-route',
    title: 'Product',
    path: '/product',
    enabled: true,
    component: Product,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'nar-route',
    title: 'NAR',
    path: '/nar',
    enabled: true,
    component: NAR,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'buying-power-route',
    title: 'Buying Power',
    path: '/buyingpower',
    enabled: true,
    component: BuyingPower,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'not-found',
    path: '/404',
    component: NotFound,
    icon: HomeOutlined,
    title: '404',
    enabled: true,
    menu: false,
    protected: false,
  },
  {
    key: 'vendors-route',
    title: 'Vendors',
    path: '/vendors',
    enabled: true,
    component: Vendors,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
];
