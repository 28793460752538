import { Status } from '../types/Status';

export const mapStatus = (
  needed: boolean,
  completed: boolean,
  pending: boolean
) => {
  if (needed) {
    return 'needed';
  }
  if (completed) {
    return 'completed';
  }
  if (pending) {
    return 'pending';
  }
  return 'onhold';
};

export const TRANSACTION_STEPS = [
  'inspections',
  'appraisal',
  'title',
  'financing',
  'closing',
] as const;

export const STEP_LABELS: Record<(typeof TRANSACTION_STEPS)[number], string> = {
  inspections: 'Inspections',
  appraisal: 'Appraisal',
  title: 'Title',
  financing: 'Financing',
  closing: 'Closing',
};

export const mapStatusToProgressBar = (
  step: (typeof TRANSACTION_STEPS)[number],
  status: Status
) => {
  switch (step) {
    case 'inspections':
      return mapStatus(
        status.inspection.needed,
        status.inspection.completed,
        status.inspection.pending
      );
    case 'appraisal':
      return mapStatus(
        status.appraisal.needed,
        status.appraisal.completed,
        status.appraisal.pending
      );
    case 'financing':
      return mapStatus(
        status.financing.needed,
        status.financing.completed,
        status.financing.pending
      );
    case 'title':
      return mapStatus(
        status.title.needed,
        status.title.completed,
        status.title.pending
      );
    case 'closing':
      return mapStatus(
        status.title.needed,
        status.title.completed,
        status.title.pending
      );
  }
};
