import { useParams, useSearchParams } from 'react-router-dom';
import inspectionsService, {
  InspectionsWithRelations,
} from '../../api/services/inspections';
import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import getInspectionStatus from '../../utils/getInspectionStatus';
import InspectionEditForm from './InspectionEditForm';
import { Prisma } from '@prisma/client';
const InspectionDetails: React.FC = () => {
  const { id } = useParams();
  const [inspection, setInspection] = useState<InspectionsWithRelations | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const tokenValid = token === inspection?.token;

  useEffect(() => {
    const fetchInspection = async () => {
      if (id) {
        const fetchedInspection = await inspectionsService.get(
          id,
          token ?? undefined
        );
        setInspection(fetchedInspection);
      }
    };
    fetchInspection();
  }, [id]);

  const handleSubmit = async (values: Prisma.inspectionsUpdateInput) => {
    console.log(values);
    if (id && token) {
      const res = await inspectionsService.update(id, values, token);
      console.log(res);
    }
  };

  if (!inspection) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{inspection.vendors?.company}</Typography>
      <Card>
        <CardContent>
          <Typography variant="body1" fontWeight="600">
            Home Details
          </Typography>
          <Grid container sx={{ mt: 1 }} spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" fontWeight="600">
                Address
              </Typography>
              <Typography variant="body2">
                {inspection.transaction?.listings?.address}
              </Typography>
              <Typography variant="body2">
                {inspection.transaction?.listings?.city},{' '}
                {inspection.transaction?.listings?.state}{' '}
                {inspection.transaction?.listings?.zip}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" fontWeight="600">
                Area (sqft)
              </Typography>
              <Typography variant="body2">
                {inspection.transaction?.listings?.area}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body1" fontWeight="600">
            Inspection Details
          </Typography>
          {tokenValid && inspection.status === 'WAITING_QUOTE' ? (
            <InspectionEditForm
              inspection={inspection}
              onSubmit={handleSubmit}
            />
          ) : (
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" fontWeight="600">
                  Status
                </Typography>
                <Typography variant="body2">
                  {getInspectionStatus(inspection.status)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" fontWeight="600">
                  Inspection Date
                </Typography>
                <Typography variant="body2">
                  {inspection.inspectionDate
                    ? new Date(inspection.inspectionDate).toLocaleString()
                    : 'Not Set'}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default InspectionDetails;
