import {
  Typography,
  Box,
  Stack,
  Slider,
  useTheme,
  Grid,
  Card,
  Divider,
} from '@mui/material';
import { formatCurrency } from '../../../utils/formatCurrency';
import { useEffect, useState } from 'react';
import ratesService from '../../../api/services/rates';
import HIW from './HIW';

interface Props {
  mobile: boolean;
  price: number;
  handlePriceChange: (price: number) => void;
}

const GuideV2: React.FC<Props> = ({ mobile, price, handlePriceChange }) => {
  const traditionalFee = price * 0.03;
  const buyersCommission = price * 0.025;
  const homepointFee = 2500;
  const buyerSavings = buyersCommission - 2500;
  const [rate, setRate] = useState<number | null>(null);
  const theme = useTheme();

  const getRates = async () => {
    const rates = await ratesService.find();
    const rate = rates.find((rate) => rate.term === '30 year');
    if (rate) {
      setRate(rate.rate);
    } else {
      setRate(6);
    }
  };

  useEffect(() => {
    getRates();
  }, []);

  return (
    <>
      <HIW />
      <Divider sx={{ opacity: 0.5, margin: mobile ? 2 : 4 }} />
      <Typography
        variant="h4"
        textAlign={'center'}
        sx={{ fontWeight: 700 }}
        pt={mobile ? 2 : 0}
      >
        See How Much You Can Save
      </Typography>
      <Typography
        variant="subtitle1"
        textAlign={'center'}
        maxWidth={'md'}
        mx={'auto'}
        mt={1}
      >
        Homepoint simplifies real estate, giving you everything you need to
        succeed —{' '}
        <span
          style={{
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
            textDecorationThickness: '3px',
          }}
        >
          at a fraction of the cost
        </span>
        .
      </Typography>
      <Stack direction="column" alignItems={'center'} pt={4}>
        <Typography variant="subtitle1" align="center">
          Savings based on a <b>${price / 1000}k</b> home. Adjust to see your
          savings.
        </Typography>
        <Slider
          min={100000}
          max={1000000}
          value={price}
          step={50000}
          onChange={(event: any) =>
            handlePriceChange(
              event.target?.value === '' ? 0 : Number(event.target?.value)
            )
          }
          sx={{ width: '100%', maxWidth: 500 }}
        />
      </Stack>
      <Grid container spacing={mobile ? 0 : 4} p={2} pt={0}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" textAlign="center" fontWeight={600} p={2}>
            Selling Savings
          </Typography>
          <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
              {/* Traditional Fee Bar */}
              <Stack spacing={1}>
                <Typography variant="subtitle2">
                  Traditional Commission (3%)
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 50,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: theme.palette.grey[300],
                        height: '100%',
                        width: `${(traditionalFee / 22000) * 100}%`,
                        minWidth: 20,
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                  <Typography variant="h6" sx={{ minWidth: 100 }}>
                    {formatCurrency(traditionalFee)}
                  </Typography>
                </Stack>
              </Stack>

              {/* Homepoint Fee Bar */}
              <Stack spacing={1} pt={1.6}>
                <Typography variant="subtitle2">Homepoint Fee</Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 50,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        height: '100%',
                        width: `${(homepointFee / 30000) * 100}%`,
                        minWidth: 20,
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ minWidth: 100, fontWeight: 600 }}
                    color="success.main"
                  >
                    {formatCurrency(homepointFee)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" textAlign="center" fontWeight={600} p={2}>
            Buying Savings
          </Typography>
          <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
              {/* Traditional Fee Bar */}
              <Stack spacing={1}>
                <Typography variant="subtitle2">
                  Buying Power with Traditional Agent (2.5%)
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 50,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: theme.palette.grey[300],
                        height: '100%',
                        width: '0%',
                        minWidth: 20,
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                  <Typography variant="h6" sx={{ minWidth: 100 }}>
                    {formatCurrency(0)}
                  </Typography>
                </Stack>
              </Stack>

              {/* Homepoint Fee Bar */}
              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ lineHeight: 0.5 }}>
                  Homepoint Buying Power
                </Typography>
                <Typography variant="caption">
                  Use it to make more competitive offers, reduce closing costs,
                  and more.
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 50,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        height: '100%',
                        width: `${(buyerSavings / 20000) * 100}%`,
                        minWidth: 20,
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ minWidth: 100, fontWeight: 600 }}
                    color="success.main"
                  >
                    {formatCurrency(buyerSavings)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default GuideV2;
