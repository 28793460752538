import { TooltipProps, Tooltip, styled } from '@mui/material';
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses';
import useIsMobile from '../../hooks/useIsMobile';

interface CustomTooltipProps extends TooltipProps {
  width?: string;
}

export const CustomTooltip = styled(
  ({ className, width, ...props }: CustomTooltipProps) => {
    const isMobile = useIsMobile();
    return (
      <Tooltip
        {...props}
        classes={{ popper: className }}
        disableTouchListener={!isMobile}
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      />
    );
  }
)<{ width?: string }>(({ theme, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    width: width || 'auto',
    maxWidth: width ? 'none' : '80vw',
    fontSize: theme.typography.pxToRem(14),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));
