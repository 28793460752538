import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import { useEffect, useState } from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Button,
  Modal,
  TextField,
  Box,
} from '@mui/material';
import {
  fetchOffers,
  offersWithUserAndListing,
} from '../../../store/slices/offers';
import { formatCurrency } from '../../../utils/formatCurrency';
import formsService from '../../../api/services/forms';
import offersService from '../../../api/services/offers';
import { MuiTelInput } from 'mui-tel-input';

const AdminOffers: React.FC = () => {
  const offers = useSelector((state: RootState) => state.offers.offers);
  const dispatch = useAppDispatch();

  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedOffer, setSelectedOffer] =
    useState<offersWithUserAndListing | null>(null);
  const [sellerEmail, setSellerEmail] = useState('');
  const [sellerPhone, setSellerPhone] = useState('');

  useEffect(() => {
    dispatch(fetchOffers());
  }, []);

  const generateDocs = async (offer: offersWithUserAndListing) => {
    const _formRes = await formsService.post({
      listingId: offer.listingId,
      formId: 6,
      offerId: offer.id,
    });
  };

  const handleSendOffer = async () => {
    if (!selectedOffer?.id) return;

    await offersService.patch(selectedOffer.id, {
      sellerEmail,
      sellerPhone: `+${sellerPhone.replace(/[^\d]/g, '')}`,
    });

    await formsService.post({
      listingId: selectedOffer.listingId,
      formId: 6,
      offerId: selectedOffer.id,
    });

    setOpenSendModal(false);
    setSellerEmail('');
    setSellerPhone('');
    setSelectedOffer(null);
  };

  return (
    <>
      <Typography variant="h6" pb={2}>
        Offers
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {offers.length > 0 && (
              <TableRow>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Buyer
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Amount
                  </Typography>
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {offers.map((offer: offersWithUserAndListing) => {
              return (
                <TableRow key={offer.id}>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Typography>
                        {offer.user.firstName} {offer.user.lastName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{formatCurrency(offer.price)}</TableCell>
                  <TableCell>{offer.status}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => generateDocs(offer)}
                      >
                        Generate Docs
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: 'white' }}
                        onClick={() => {
                          setSelectedOffer(offer);
                          setOpenSendModal(true);
                        }}
                      >
                        Send Offer
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openSendModal}
        onClose={() => setOpenSendModal(false)}
        aria-labelledby="send-offer-modal"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" component="h2" mb={2}>
            Send Offer
          </Typography>
          <Stack spacing={2}>
            <TextField
              label="Seller Email"
              value={sellerEmail}
              onChange={(e) => setSellerEmail(e.target.value)}
              fullWidth
            />
            <MuiTelInput
              value={sellerPhone}
              onChange={(value: string) => setSellerPhone(value)}
              onlyCountries={['US']}
              defaultCountry="US"
              label="Seller Phone"
              forceCallingCode={true}
              fullWidth
            />
            <Button variant="contained" onClick={handleSendOffer} fullWidth>
              Send
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AdminOffers;
