import { Box } from '@mui/material';

const Circle: React.FC<{ size: number; color: string; sx?: any }> = ({
  size,
  color,
  sx,
}) => {
  return (
    <Box
      sx={{
        minWidth: size,
        minHeight: size,
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
        ...sx,
      }}
    />
  );
};

export default Circle;
