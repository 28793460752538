import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import pricingOpinionsService, {
  PricingOpinionWithListings,
} from '../../api/services/priceOpinion';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { formatCurrency } from '../../utils/formatCurrency';
import { useEffect, useState } from 'react';
import { showNewMessage } from '@intercom/messenger-js-sdk';
import closingCostsService from '../../api/services/closingCosts';
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip';
import { InfoOutlined } from '@mui/icons-material';

interface PriceSelectionProps {
  priceOpinion: PricingOpinionWithListings;
  onApprove: (price: number) => void;
}

const TitleFeeExplanation = () => {
  return (
    <Box>
      <Typography variant="body1">
        Title fees cover the costs of ensuring the property title is clear for
        transfer to the buyer.
      </Typography>
      <br />
      <Typography variant="body1">
        1. <strong>Title Search:</strong> Verifies ownership and checks for
        liens or claims.
        <br />
        2. <strong>Title Insurance:</strong> Protects the buyer from future
        title disputes.
        <br />
        3. <strong>Settlement Fee:</strong> Covers closing and paperwork
        handling.
      </Typography>
    </Box>
  );
};

const PriceSelection = ({ priceOpinion, onApprove }: PriceSelectionProps) => {
  const { price1, price2, price3, approvedPrice } = priceOpinion;
  const [selectedPrice, setSelectedPrice] = useState<string | null>(
    approvedPrice?.toString() ?? null
  );
  const [closingCosts, setClosingCosts] = useState<any[]>([]);

  const getClosingCosts = async (price: number) => {
    const closingCosts = await closingCostsService.find(
      priceOpinion.listings.id,
      undefined,
      price
    );
    return closingCosts;
  };

  const closingCostsForPrices = async () => {
    const prices = [price1, price2, price3];
    const closingCosts = await Promise.all(prices.map(getClosingCosts));
    setClosingCosts(closingCosts);
  };

  useEffect(() => {
    closingCostsForPrices();
  }, []);

  const handleApprove = async () => {
    if (selectedPrice) {
      await pricingOpinionsService.patch(priceOpinion.id, {
        approvedPrice: parseInt(selectedPrice),
      });
      onApprove(parseInt(selectedPrice));
    }
  };

  const handleDiscuss = () => {
    showNewMessage('I need to discuss the price of my home.');
  };

  if (closingCosts.length === 0) {
    return <CircularProgress />;
  }

  return (
    <Grid2 container>
      <RadioGroup
        name="price-selection"
        sx={{ width: '100%' }}
        value={selectedPrice}
      >
        <Grid2 container spacing={2}>
          {[price1, price2, price3].map((price, index) => (
            <Grid2 xs={12} md={4} key={index}>
              <Card
                sx={{
                  p: 2,
                  cursor: approvedPrice ? 'default' : 'pointer',
                  '&:hover': {
                    backgroundColor: 'grey.50',
                  },
                }}
              >
                <label
                  style={{
                    display: 'flex',
                    width: '100%',
                    cursor: approvedPrice ? 'default' : 'pointer',
                  }}
                >
                  <Stack direction="column" spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{ flexGrow: 1 }}
                    >
                      <Typography variant="h5">
                        {formatCurrency(price)}
                      </Typography>
                      <Typography>/</Typography>
                      <Typography>
                        {formatCurrency(price / priceOpinion.listings.area)} per
                        sq ft
                      </Typography>
                      <Radio
                        value={price.toString()}
                        onChange={() => setSelectedPrice(price.toString())}
                        disabled={
                          approvedPrice != null && approvedPrice !== price
                        }
                      />
                    </Stack>
                    <Divider />
                    <Typography variant="body2">Estimated Net Sheet</Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Attorney Fee:</span>
                      <span>
                        {formatCurrency(closingCosts[index].attorneyFee)}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Termite Fee:</span>
                      <span>
                        {formatCurrency(closingCosts[index].termiteFee)}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Homepoint Fee:</span>
                      <span>
                        {formatCurrency(closingCosts[index].brokerFee)}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontWeight={600}>Title Fee</Typography>
                        <CustomTooltip title={<TitleFeeExplanation />}>
                          <InfoOutlined />
                        </CustomTooltip>
                      </Stack>
                      <span>
                        {formatCurrency(closingCosts[index].titleFee)}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Tax Fee:</span>
                      <span>{formatCurrency(closingCosts[index].taxFee)}</span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Misc Fee:</span>
                      <span>{formatCurrency(closingCosts[index].miscFee)}</span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        Buyer&apos;s Agent Fee:
                      </span>
                      <span>{formatCurrency(closingCosts[index].bac)}</span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        Total Closing Costs:
                      </span>
                      <span>{formatCurrency(closingCosts[index].costs)}</span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Mortgage Balance:</span>
                      <span>
                        {formatCurrency(closingCosts[index].mortgageBalance)}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span style={{ fontWeight: 600 }}>Proceeds:</span>
                      <span>
                        {formatCurrency(closingCosts[index].proceeds)}
                      </span>
                    </Typography>
                    <Typography variant="caption">
                      All fees are estimates and are subject to change.
                    </Typography>
                  </Stack>
                </label>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </RadioGroup>
      {!approvedPrice && (
        <Grid2 xs={12} sx={{ mt: 2 }}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="success"
              disabled={!selectedPrice}
              onClick={handleApprove}
            >
              Approve
            </Button>
            <Button variant="contained" color="primary" onClick={handleDiscuss}>
              Discuss
            </Button>
          </Stack>
        </Grid2>
      )}
    </Grid2>
  );
};

export default PriceSelection;
