import { useFeatureFlagVariantKey } from 'posthog-js/react';
import LandingV1 from './LandingV1';
import LandingV2 from './LandingV2';

const LandingExperiment = () => {
  const variant = useFeatureFlagVariantKey('landingv3');
  if (variant === 'test') {
    return <LandingV2 />;
  }
  return <LandingV1 />;
};

export default LandingExperiment;
