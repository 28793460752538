import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

const vendorsService = {
  post: async (vendor: Prisma.vendorsCreateInput) => {
    const res = await axiosInstance.post('/vendors', vendor);
    return res;
  },
};

export default vendorsService;
