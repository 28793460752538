import {
  Stack,
  Typography,
  Modal,
  Box,
  TextField,
  Button,
} from '@mui/material';
import useIsMobile from '../../hooks/useIsMobile';
import { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MuiTelInput } from 'mui-tel-input';
import vendorsService from '../../api/services/vendors';

const Vendors: React.FC = () => {
  const mobile = useIsMobile();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      serviceArea: '',
      info: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone: Yup.string().required('Required'),
      company: Yup.string().required('Required'),
      serviceArea: Yup.string().required('Required'),
      info: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        await vendorsService.post(values);
        setSubmitted(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Stack
      direction="column"
      spacing={2}
      p={mobile ? 2 : 4}
      pt={0}
      alignItems="center"
    >
      <Typography variant="h4" fontWeight={600}>
        Vendors
      </Typography>
      <Typography variant="body1">
        We are always looking for new vendors to partner with. If you are an
        inspector or appraiser, we are intersted in you joining our network.
      </Typography>
      <Typography variant="h5" fontWeight={600}>
        How does it work?
      </Typography>
      <Typography variant="body1">
        Homepoint is a real estate transaction platform and brokerage based here
        in Alabama. Home buyers are able to navigate the entire home buying
        process. Being part of Homepoint, you&apos;ll receive quote requests
        from our buyers needing a home inspected, and from there, you can send
        back a quote amount, date of inspection, etc., for approval.
        <br />
        <br />
        We independently vet all vendors to ensure quality and customer service.
        Consumers will have always have the choice to send out multiple requests
        to different vendors.
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={6}>
          <img
            src="/inspections_quote_approve.png"
            width={'100%'}
            style={{ borderRadius: 8, cursor: 'pointer' }}
            onClick={() => handleImageClick('/inspections_quote_approve.png')}
          />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <img
            src="/inspections_quote_confirmed.png"
            width={'100%'}
            style={{ borderRadius: 8, cursor: 'pointer' }}
            onClick={() => handleImageClick('/inspections_quote_confirmed.png')}
          />
        </Grid2>
      </Grid2>
      {!submitted ? (
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            maxWidth: 600,
            mx: 'auto',
            width: '100%',
          }}
        >
          <Typography variant="h5" fontWeight={600}>
            Interested in partnering with us?
          </Typography>
          <Typography variant="body1">
            Please fill out the form below and we&apos;ll get back to you
            shortly.
          </Typography>
          <TextField
            fullWidth
            label="Full Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ backgroundColor: 'white' }}
          />
          <MuiTelInput
            value={formik.values.phone}
            onChange={(value: string) => formik.setFieldValue('phone', value)}
            onlyCountries={['US']}
            defaultCountry="US"
            label="Phone"
            forceCallingCode={true}
            fullWidth
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            fullWidth
            label="Company Name"
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            fullWidth
            label="Service Area"
            name="serviceArea"
            value={formik.values.serviceArea}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.serviceArea && Boolean(formik.errors.serviceArea)
            }
            helperText={formik.touched.serviceArea && formik.errors.serviceArea}
            sx={{ backgroundColor: 'white' }}
          />
          <TextField
            fullWidth
            label="Additional Information"
            name="info"
            multiline
            rows={4}
            value={formik.values.info}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.info && Boolean(formik.errors.info)}
            helperText={formik.touched.info && formik.errors.info}
            sx={{ backgroundColor: 'white' }}
          />
          <Button type="submit" variant="contained" fullWidth>
            Submit
          </Button>
        </Box>
      ) : (
        <Typography variant="body1" textAlign="center">
          Thank you for your interest in partnering with us! We will reach out
          to you shortly.
        </Typography>
      )}

      <Modal
        open={!!selectedImage}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={selectedImage || ''}
          sx={{
            maxHeight: '90vh',
            maxWidth: '90vw',
            objectFit: 'contain',
          }}
          onClick={handleCloseModal}
        />
      </Modal>
    </Stack>
  );
};

export default Vendors;
