import { Stack, Typography, Box } from '@mui/material';
import { CustomTooltip } from './CustomTooltip/CustomTooltip';
import {
  mapStatusToProgressBar,
  STEP_LABELS,
  TRANSACTION_STEPS,
} from '../utils/mapTransactionStatus';
import { Status } from '../types/Status';

interface ProgressStepProps {
  label: string;
  status: 'completed' | 'active' | 'onhold' | 'needed' | 'pending';
}

const ProgressStep: React.FC<ProgressStepProps> = ({ label, status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'completed':
        return '#4CAF50'; // Green
      case 'pending':
        return '#2196F3'; // Blue
      case 'onhold':
        return '#9E9E9E'; // Gray
      case 'needed':
        return '#FF9800'; // Orange
      default:
        return '#9E9E9E';
    }
  };

  return (
    <CustomTooltip
      title={`Status: ${status.charAt(0).toUpperCase() + status.slice(1)}`}
    >
      <Stack alignItems="center" spacing={1}>
        <Box
          sx={{
            width: 100,
            height: 20,
            backgroundColor: getStatusColor(),
            borderRadius: 20,
            opacity: 1,
          }}
        />
        <Typography variant="body1">{label}</Typography>
      </Stack>
    </CustomTooltip>
  );
};

interface TransactionProgressBarProps {
  status: Status;
}

const TransactionProgressBar: React.FC<TransactionProgressBarProps> = ({
  status,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="flex-start"
    >
      {TRANSACTION_STEPS.map((step) => (
        <ProgressStep
          key={step}
          label={STEP_LABELS[step]}
          status={mapStatusToProgressBar(step, status)}
        />
      ))}
    </Stack>
  );
};

export default TransactionProgressBar;
