import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store/store';
import { logout, validate } from '../store/slices/auth';
import { validate as validateApi } from '../api/services/authentication';
import Intercom from '@intercom/messenger-js-sdk';
import { users } from '@prisma/client';
import { useNavigate } from 'react-router-dom';
import { H } from 'highlight.run';

interface AuthContextType {
  isAuthenticated: boolean;
  logout: () => void;
  currentUser: users | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const dispatch = useAppDispatch();
  const isAuthenticated = !!currentUser;
  const navigate = useNavigate();

  Intercom({
    app_id: 'no8y08a0',
    custom_launcher_selector: isAuthenticated ? '#help' : undefined,
    hide_default_launcher: isAuthenticated,
    email: currentUser?.email ?? undefined,
    user_id: currentUser?.id,
    name: currentUser
      ? `${currentUser?.firstName} ${currentUser?.lastName}`
      : '',
    phone: currentUser?.phone ?? undefined,
  });

  const logoutDispatch = () => {
    dispatch(logout());
    navigate('/login');
  };

  const validateApiOnly = async () => {
    try {
      await validateApi();
    } catch (error) {
      console.error(error);
      logoutDispatch();
    }
  };

  const validateToken = async () => {
    try {
      await dispatch(validate());
    } catch (error) {
      console.error(error);
      logoutDispatch();
    }
  };

  useEffect(() => {
    if (currentUser) {
      H.identify(currentUser.id, {
        email: currentUser.email ?? '',
      });
    }

    // Add event listener for visibility change
    const handleVisibilityChange = () => {
      if (!document.hidden && currentUser) {
        validateApiOnly();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      validateToken();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, logout: logoutDispatch, currentUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
