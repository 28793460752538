import {
  Stack,
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  IconButton,
  AppBar, // Add this import
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { show } from '@intercom/messenger-js-sdk';

const getMenuButons = (isMobile: boolean) => {
  if (isMobile) {
    return [
      // {
      //   title: 'Buying',
      //   url: '/?type=buyer',
      // },
      // {
      //   title: 'Selling',
      //   url: '/?type=seller',
      // },
      {
        title: 'How It Works',
        url: '/product',
      },
      {
        title: 'About Us',
        url: '/aboutus',
      },
      {
        title: 'Agents',
        url: '/agents',
      },
      // {
      //   title: 'Buying Power',
      //   url: '/buyingpower',
      // },
      {
        title: 'Support',
        onClick: () => {
          show();
        },
      },
    ];
  }
  return [
    // {
    //   title: 'Buying Power',
    //   url: '/buyingpower',
    // },
    {
      title: 'NAR Settlement',
      url: '/nar',
    },
  ];
};

const LandingButtons: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const nonMobile = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Ensure this is the clicked button
  };
  const handleMenuClick = (item?: string) => {
    setAnchorEl(null);
    if (item) {
      navigate(item, { replace: true });
    }
  };
  const open = Boolean(anchorEl);

  const HomepointMenu = () => (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleMenuClick()}
      anchorOrigin={{
        vertical: 'bottom', // Adjusted to align the menu below the button
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top', // Adjusted to align the menu below the button
        horizontal: 'left',
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {getMenuButons(!nonMobile).map((item, index) => {
        return (
          <MenuItem
            key={index}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              if (item.url) {
                handleMenuClick(item.url);
              }
              setAnchorEl(null);
            }}
          >
            {item.title}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const MobileMenu = () => (
    <Stack direction="row" spacing={1}>
      <Link to="/">
        <img
          src="/favicon.png"
          style={{
            marginLeft: '4px',
            marginTop: '8px',
            width: 40,
          }}
        />
      </Link>
      <HomepointMenu />
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
    </Stack>
  );

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={1}
      sx={{ borderRadius: 2, backgroundColor: '#ffffff' }}
    >
      <Stack
        direction="row"
        flex={1}
        justifyContent={'space-between'}
        alignItems={'center'}
        ml={0}
        p={1}
      >
        {nonMobile ? (
          <Link to="/">
            <img
              src="/new_logo_light.png"
              style={{
                marginLeft: '4px',
                marginTop: '8px',
                width: '100%',
                maxWidth: 200,
                height: 'auto',
              }}
            />
          </Link>
        ) : (
          <MobileMenu />
        )}
        <Stack direction={'row'} spacing={1}>
          {nonMobile ? (
            <>
              <HomepointMenu />
              <Button
                component={Link}
                to="/listings"
                variant="text"
                color="inherit"
                sx={{ fontWeight: 700 }}
              >
                Homes for Sale
              </Button>
              {/* <Button
                variant="text"
                color="inherit"
                sx={{ fontWeight: 700 }}
                component={Link}
                to="/?type=buyer"
              >
                Buying
              </Button>
              <Button
                variant="text"
                color="inherit"
                sx={{ fontWeight: 700 }}
                component={Link}
                to="/?type=seller"
              >
                Selling
              </Button> */}
              <Button
                variant="text"
                color="inherit"
                sx={{ fontWeight: 700 }}
                component={Link}
                to="/product"
              >
                How It Works
              </Button>
              <Button
                variant="text"
                color="inherit"
                sx={{ fontWeight: 700 }}
                component={Link}
                to="/aboutus"
              >
                About Us
              </Button>
              <Button
                variant="text"
                color="inherit"
                sx={{ fontWeight: 700 }}
                onClick={() => show()}
              >
                Support
              </Button>
            </>
          ) : (
            <>
              <Button
                component={Link}
                to="/listings"
                variant="contained"
                color="primary"
              >
                For Sale
              </Button>
              <HomepointMenu />
            </>
          )}
          <Button
            component={Link}
            to="/login"
            variant="contained"
            color="highlight"
            sx={{
              textWrap: 'nowrap',
              height: 50,
              fontWeight: 700,
            }}
          >
            Log In
          </Button>
        </Stack>
      </Stack>
    </AppBar>
  );
};

export default LandingButtons;
