import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ForSale from '/for_sale.svg';
import { PopupModal } from 'react-calendly';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2';

const AgentLandingV2 = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const veryMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const id = location.hash.substring(1); // Remove the '#' character
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const quotes = [
    {
      quote:
        // eslint-disable-next-line max-len
        'I need more efficient ways to manage my transactions and client relationships.',
    },
    {
      quote:
        // eslint-disable-next-line max-len
        'My clients are asking for more flexible service options and commission structures.',
    },
    {
      quote: 'I want to differentiate my business in a competitive market.',
    },
    {
      quote: 'Managing paperwork and processes is taking too much of my time.',
    },
  ];

  const Info = ({
    title,
    subtitle,
    icon,
  }: {
    title: string;
    subtitle: JSX.Element;
    icon: string;
  }) => (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Typography variant="h3">{icon}</Typography>
      <Stack direction={'column'} width={'100%'}>
        <Typography variant="body1" fontWeight={600}>
          {title}
        </Typography>
        {subtitle}
      </Stack>
    </Stack>
  );

  const Quote = ({ quote, color }: { quote: string; color: string }) => (
    <Typography
      variant="body1"
      sx={{
        fontWeight: 500,
        color,
      }}
    >
      <span style={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
        &#8220;
      </span>
      {quote}
      <span style={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
        &#8221;
      </span>
    </Typography>
  );

  return (
    <>
      <Grid container spacing={2} p={mobile ? 0 : 2}>
        <Grid xs={12} sm={6}>
          <Stack direction={'column'} textAlign={mobile ? 'center' : undefined}>
            <span>
              <Stack mb={1} mt={mobile ? 0 : -1} spacing={1}>
                <Typography variant={mobile ? 'h4' : 'h3'} fontWeight={700}>
                  Modernize your real estate business.
                </Typography>
                <Typography variant="body1">
                  Expand your reach and streamline operations with our
                  innovative platform. Homepoint gives you the tools to offer
                  flexible solutions while growing your business efficiently.
                </Typography>
              </Stack>
              <Stack
                direction={'row'}
                spacing={1}
                mt={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Typography variant="h6" fontWeight={600}>
                  Want to learn more?
                </Typography>
                <Button
                  onClick={() => setCalendlyOpen(true)}
                  variant="contained"
                  color="primary"
                  sx={{ fontSize: '1.2rem', fontWeight: 600 }}
                >
                  Let&apos;s Chat
                </Button>
              </Stack>
              <PopupModal
                url="https://calendly.com/trey-homepoint"
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById('root') as HTMLElement}
                onModalClose={() => setCalendlyOpen(false)}
                open={calendlyOpen}
              />
            </span>
          </Stack>
        </Grid>
        {!veryMobile && (
          <Grid sm={6}>
            <img
              src={ForSale}
              style={{ width: '100%', maxWidth: 400 }}
              alt="For Sale"
            />
          </Grid>
        )}
      </Grid>

      {/* Customer Problem */}
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid container spacing={2} textAlign={'center'}>
          <Grid xs={12}>
            <Stack direction="column" mt={0} spacing={1}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Does this sound familiar?
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <Stack direction="row" spacing={1}>
              <Quote
                quote={quotes[0].quote}
                color={theme.palette.common.black}
              />
              <Quote
                quote={quotes[2].quote}
                color={theme.palette.common.black}
              />
            </Stack>
          </Grid>
          <Grid xs={12} justifyItems={'center'}>
            <Stack direction="row" spacing={1}>
              <Quote
                quote={quotes[3].quote}
                color={theme.palette.common.black}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Divider sx={{ opacity: 0.5, margin: 4 }} />

      {/* Guide */}
      <Typography
        variant={mobile ? 'h5' : 'h4'}
        textAlign={'center'}
        sx={{ fontWeight: 700, mt: 4 }}
      >
        A Modern Real Estate Platform That Works for You
      </Typography>
      <Typography variant="subtitle1" textAlign={'center'} mt={1}>
        Homepoint enables agents to offer flexible solutions to attract more
        customers and grow their business.
      </Typography>
      <br />
      <Grid2 container spacing={2} textAlign={'center'} p={2}>
        <Grid2 xs={12} md={4}>
          <Info
            title="Online Platform"
            subtitle={
              <Typography variant="subtitle1">
                Manage your transactions and client relationships in one place.
              </Typography>
            }
            icon="💻"
          />
        </Grid2>
        {!mobile && (
          <Grid2 xs={12} md={4}>
            <Info
              title="New Revenue Streams"
              subtitle={
                <Typography variant="subtitle1">
                  Offer new services to your clients and grow your business.
                </Typography>
              }
              icon="💰"
            />
          </Grid2>
        )}
        {!mobile && (
          <Grid2 xs={12} md={4}>
            <Info
              title="Smart Electronic Lockbox"
              subtitle={
                <Typography variant="subtitle1">
                  Camera, platform connected lockbox included for modern
                  showings.
                </Typography>
              }
              icon="🔑"
            />
          </Grid2>
        )}
        <Grid2 xs={12} md={4}>
          <Info
            title="Chat with Clients"
            subtitle={
              <Typography variant="subtitle1">
                Chat directly with clients on the platform.
              </Typography>
            }
            icon="👩‍💼"
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Info
            title="Vendor Management"
            subtitle={
              <Typography variant="subtitle1">
                Enable clients to coordinate inspections and vendors directly
                through the platform.
              </Typography>
            }
            icon="🔧"
          />
        </Grid2>
        {!mobile && (
          <Grid2 xs={12} md={4}>
            <Info
              title="Expand Your Business"
              // eslint-disable-next-line max-len
              subtitle={
                <Typography variant="subtitle1">
                  Utilize an integrated network of showing agents to expand your
                  business.
                </Typography>
              }
              icon="🌐"
            />
          </Grid2>
        )}
        <Grid2 container spacing={2} textAlign={'left'} mt={mobile ? 0 : 4}>
          <Grid2 xs={12} md={6}>
            <Typography variant="h6" fontWeight={600} pt={2}>
              For Your Sellers
            </Typography>
            <Stack direction={'column'} spacing={1}>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">💰</Typography>
                <Typography variant="body1">
                  Simplified offer review and negotiation process.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🔒</Typography>
                <Typography variant="body1">
                  Camera, platform connected lockbox included for modern
                  showings.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">📅</Typography>
                <Typography variant="body1">
                  Manage showings, offers, and communication in one place.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">💬</Typography>
                <Typography variant="body1">
                  Sellers receive feedback on their home directly through the
                  platform from potential buyers and agents.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🎉</Typography>
                <Typography variant="body1">And more!</Typography>
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <img
              src="/demo_1.png"
              alt="Seller Dashboard"
              width={'100%'}
              style={{
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick('/demo_1.png')}
            />
          </Grid2>
          {!mobile && (
            <Grid2 xs={12} md={6} justifyContent={'center'}>
              <img
                src="/demo_transactions.png"
                alt="Transaction Dashboard"
                width={mobile ? '100%' : '95%'}
                style={{
                  boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
                  borderRadius: '12px',
                  cursor: 'pointer',
                }}
                onClick={() => handleImageClick('/demo_transactions.png')}
              />
            </Grid2>
          )}
          <Grid2 xs={12} md={6}>
            <Typography variant="h6" fontWeight={600}>
              For Your Buyers
            </Typography>
            <Stack direction={'column'} spacing={1}>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🔍</Typography>
                <Typography variant="body1">
                  Differentiate your business with flexible service models that
                  save you time.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">📅</Typography>
                <Typography variant="body1">
                  Expand your business automatically with an integrated network
                  of showing agents.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">💰</Typography>
                <Typography variant="body1">
                  Step-by-step offer submission from buyers for your team to
                  review.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">💻</Typography>
                <Typography variant="body1">
                  Transaction tracking via a convenient online dashboard.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🔧</Typography>
                <Typography variant="body1">
                  Schedule inspections and coordinate vendors directly through
                  the platform.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant="body1">🎉</Typography>
                <Typography variant="body1">And more!</Typography>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
        {mobile && (
          <Grid2 xs={12} md={6} justifyContent={'center'}>
            <img
              src="/demo_transactions.png"
              alt="Transaction Dashboard"
              width={mobile ? '100%' : '95%'}
              style={{
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick('/demo_transactions.png')}
            />
          </Grid2>
        )}
        <Grid2 xs={12} mt={2}>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ maxWidth: 300, mt: 2, fontWeight: 700 }}
              onClick={() => navigate('/product')}
            >
              View More Features
            </Button>
          </Stack>
        </Grid2>
      </Grid2>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="lg" sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h4" fontWeight={600}>
          Mission
        </Typography>
        <br />
        <Typography variant="h6" fontWeight={500}>
          &#8220;Homepoint partners with forward-thinking agents and brokerages
          to modernize real estate transactions. Our platform provides the
          technology and flexibility you need to serve today&apos;s clients
          while growing your business.&#8221;
        </Typography>
      </Container>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />

      <Container maxWidth="lg" sx={{ mt: 4, textAlign: 'center', mb: 4 }}>
        <Typography variant="h5" fontWeight={600}>
          Ready to modernize your business?
        </Typography>
        <Button
          onClick={() => setCalendlyOpen(true)}
          variant="contained"
          color="highlight"
          sx={{ fontSize: '1.2rem', mt: 2, fontWeight: 600 }}
        >
          Let&apos;s Chat
        </Button>
      </Container>

      <Dialog
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        maxWidth="lg"
        onClick={() => setSelectedImage(null)}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </Dialog>
    </>
  );
};

export default AgentLandingV2;
