import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prisma } from '@prisma/client';
import transactionsService from '../../api/services/transactions';

export type transactionsWithUserAndOffer = Prisma.transactionsGetPayload<{
  include: { user: true; offer: true };
}>;

interface State {
  transactions: transactionsWithUserAndOffer[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  transactions: [],
  loading: false,
  error: null,
};

export const fetchTransactions = createAsyncThunk(
  'transactions/fetch',
  async (_params, { rejectWithValue }) => {
    try {
      const trans = await transactionsService.find();
      return trans;
    } catch (error) {
      return rejectWithValue('Failed to fetch trans');
    }
  }
);

const transSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTransactions.fulfilled,
        (state, action: PayloadAction<transactionsWithUserAndOffer[]>) => {
          state.loading = false;
          state.transactions = action.payload;
        }
      )
      .addCase(
        fetchTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default transSlice.reducer;
