import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import listingsService from '../../api/services/listings';

const ListingBySlug: React.FC = () => {
  const { listingSlug } = useParams<{ listingSlug: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        if (!listingSlug) return;

        // Get current search params
        const searchParams = new URLSearchParams(window.location.search);

        // Parse/sanitize the slug
        const parsedSlug = decodeURIComponent(listingSlug)
          .toLowerCase()
          .trim()
          .replace(/[^a-z0-9-]/g, '') // Only allow lowercase letters, numbers, and hyphens
          .replace(/-/g, ' '); // Replace hyphens with spaces

        const listing = await listingsService.find({
          params: {
            where: {
              address: {
                mode: 'insensitive',
                startsWith: parsedSlug,
              },
            },
          },
        });

        if (listing) {
          // Redirect to the canonical listing URL with the ID and preserve query params
          navigate(
            `/listings/${listing[0].id}${
              searchParams.toString() ? `?${searchParams.toString()}` : ''
            }`,
            { replace: true }
          );
        } else {
          // Handle case where listing is not found
          navigate('/404', { replace: true });
        }
      } catch (error) {
        console.error('Error fetching listing by slug:', error);
        navigate('/404', { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    fetchListing();
  }, [listingSlug, navigate]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return null; // Component will redirect before rendering
};

export default ListingBySlug;
