import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectUserId = (state: RootState) => state.auth.user?.id;
export const selectListings = (state: RootState) => state.listings.listings;

export const selectListingById = createSelector(
  [selectListings],
  (listings) => (id: string) => listings.find((listing) => listing.id === id)
);

export const selectMyBuyingListings = createSelector(
  [selectListings, selectUserId],
  (listings, userId) =>
    listings
      .filter((listing) => listing.userId === userId)
      .filter((listing) => listing.type === 'BUYER')
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
);

export const selectMySellingListings = createSelector(
  [selectListings, selectUserId],
  (listings, userId) =>
    listings
      .filter((listing) => listing.userId === userId)
      .filter((listing) => listing.type === 'SELLER')
);
