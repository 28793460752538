// eslint-disable-next-line max-len
import { transactionsWithUserAndOffer } from '../../../store/slices/transactions';

interface Props {
  transaction: transactionsWithUserAndOffer;
}

const InspectionCard: React.FC<Props> = ({ transaction }) => {
  return <div>InspectionCard</div>;
};

export default InspectionCard;
