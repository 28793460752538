import axiosInstance from '../api';
import { Status } from '../../types/Status';

const statusService = {
  get: async ({ id }: { id: string }): Promise<Status> => {
    const response = await axiosInstance.get(`/status/${id}`);
    return response.data;
  },
};

export default statusService;
