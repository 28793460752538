import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { offers } from '@prisma/client';
import { capitalize } from 'lodash';
import NiceModal from '@ebay/nice-modal-react';
import OfferConfirmModal, {
  OFFER_CONFIRM_MODAL_TYPE,
} from '../OfferConfirmationModal/OfferConfirmModal';

interface OfferActionsProps {
  offer: offers;
  onOfferAccepted: (offer: offers) => void;
  onActionComplete: () => void;
}

export const OfferActions: React.FC<OfferActionsProps> = ({
  offer,
  onOfferAccepted,
  onActionComplete,
}) => {
  const { status } = offer;

  if (status !== 'PENDING') {
    return (
      <Typography color={status === 'ACCEPTED' ? 'success.main' : 'error.main'}>
        {capitalize(status)}
      </Typography>
    );
  }

  return (
    <Stack direction="row" spacing={1}>
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          NiceModal.show(OfferConfirmModal, {
            handleOnSubmit: onOfferAccepted,
            offer,
            type: OFFER_CONFIRM_MODAL_TYPE.ACCEPT,
          });
        }}
      >
        Accept
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          NiceModal.show(OfferConfirmModal, {
            handleOnSubmit: onActionComplete,
            offer,
            type: OFFER_CONFIRM_MODAL_TYPE.COUNTER,
          });
        }}
      >
        Counter
      </Button>
      <Button
        color="error"
        variant="outlined"
        onClick={() => {
          NiceModal.show(OfferConfirmModal, {
            handleOnSubmit: onActionComplete,
            offer,
            type: OFFER_CONFIRM_MODAL_TYPE.DECLINE,
          });
        }}
      >
        Decline
      </Button>
    </Stack>
  );
};
