import { Stack, Typography } from '@mui/material';
import { transactionsWithUserAndOffer } from '../../store/slices/transactions';
import { differenceInCalendarDays } from 'date-fns';
import TransactionProgressBar from '../../components/TransactionProgressBar';
import { selectListingById } from '../../store/selectors/listings';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { formatCurrency } from '../../utils/formatCurrency';
import { selectStatus } from '../../store/selectors/status';
import { fetchStatus } from '../../store/slices/status';
import { useEffect } from 'react';

interface Props {
  transaction: transactionsWithUserAndOffer;
}

const TransactionStatus: React.FC<Props> = ({ transaction }) => {
  const dispatch = useAppDispatch();
  const closeDate = transaction.offer?.closingDate;
  const listing = useSelector((state: RootState) =>
    selectListingById(state)(transaction.listingId)
  );
  const status = useSelector((state: RootState) => selectStatus(state));

  let daysToClose;
  if (closeDate) {
    daysToClose = differenceInCalendarDays(new Date(), new Date(closeDate));
  }

  useEffect(() => {
    dispatch(fetchStatus(transaction.listingId));
  }, [transaction.listingId]);

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        p={4}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {status && <TransactionProgressBar status={status} />}
        {daysToClose && (
          <Stack direction="column" alignItems={'center'}>
            <Typography variant="h6">{daysToClose}</Typography>
            <Typography variant="body1">Days to close</Typography>
          </Stack>
        )}
        <Stack direction="column">
          <Typography fontWeight={500}>{listing?.address}</Typography>
          <Typography fontWeight={600} color={'success.main'}>
            {formatCurrency(transaction.offer?.price ?? 0)}
          </Typography>
        </Stack>
      </Stack>
      {/* <Stack direction="row" justifyContent={'space-between'} p={2}>
        <InspectionStatus transaction={transaction} />
        <FinanceStatus status={transaction.financing} />
        <TitleStatus status={transaction.title} />
        <AppraisalStatus status={transaction.appraisal} />
      </Stack> */}
    </Stack>
  );
};

export default TransactionStatus;
