import React from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ: React.FC = () => {
  return (
    <Container id="faq" maxWidth="lg">
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, marginBottom: 4, textAlign: 'center' }}
      >
        FAQs
      </Typography>
      <Typography variant="h5">General</Typography>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What is Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint is a real estate platform and service that combines
            technology with expert support to make buying or selling your home
            simpler and more affordable. The platform guides you through clear
            milestones, providing structure and transparency throughout the
            process. At the same time, our experienced agents are available to
            assist whenever you need personalized help. For a flat fee of
            $2,500, paid at closing, Homepoint offers a seamless blend of
            technology and service, making real estate transactions more
            straightforward and cost-effective.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How does Homepoint differ from a traditional real estate agent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint offers a more flexible, tech-driven solution compared to
            traditional agents. While traditional agents charge a
            percentage-based commission, Homepoint uses a flat fee, which can
            save you money. Our platform is designed to empower users with tools
            and resources, but we also provide real estate professional support
            when necessary.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            Can I get help from a real estate agent if I need it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutely! A licensed agent is always available to assist you at
            any point in the buying or selling process.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Typography variant="h5">Selling</Typography>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How does selling a home through Homepoint work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Selling through Homepoint is straightforward and it begins on our
            platform. You fill out your listing details, we suggest a home price
            or you set your own, and then we list and market your home. From
            there, messages from potential buyers, showing requests, and offers
            are organized in an easy to use dashboard. Once you accept an offer,
            documents are automatically generated and sent out. A real estate
            pro is available at all times to help in the process.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What are the costs of selling with Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Instead of paying a traditional percentage-based commission,
            Homepoint charges a flat fee of $2,500. This provides significant
            savings, while still giving you access to agent support throughout
            the process.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            Can Homepoint help with pricing my home?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes. Homepoint offers you the option to have an agent price your
            home, or if you already have a price in mind, you can set your own
            price using Homepoint&apos;s tools and market data to ensure
            it&apos;s competitive.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How are negotiations handled?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint&apos;s platform organizes offers in an easy to compare
            view. An agent is always available to help with counteroffers,
            negotiations, or contract terms.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What all services are included?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul style={{ margin: 0 }}>
            <li>
              <strong>Flat Fee Pricing</strong>: Pay only $2,500 at closing
              instead of a traditional percentage-based commission.
            </li>
            <li>
              <strong>Offer Negotiation Support</strong>: Professional guidance
              for reviewing and negotiating offers.
            </li>
            <li>
              <strong>Property Listing on MLS</strong>: Get your property listed
              on the Multiple Listing Service (MLS).
            </li>
            <li>
              <strong>Comprehensive Marketing</strong>: Includes social media
              advertising, a yard sign, professional photos, and a detailed
              floor plan to attract potential buyers.
            </li>
            <li>
              <strong>Showing Management</strong>: Manage property showings
              directly through the platform.
            </li>
            <li>
              <strong>Privacy Protection</strong>: A dedicated phone number is
              provided with the listing to keep your contact information
              private.
            </li>
            <li>
              <strong>Professional Documentation</strong>: All required
              contracts, disclosures, and closing paperwork.
            </li>
            <li>
              <strong>Expert Real Estate Advice</strong>: Access to agents at
              any stage of the process.
            </li>
            <li>
              <strong>Online Dashboard</strong>: Track offers, manage inquiries,
              and view your transaction status from start to finish.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion> */}
      <br />
      <Typography variant="h5">Buying</Typography>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How does buying a home through Homepoint work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint enables consumers to schedule home tours without
            commitment to an agent, prepare and make expert reviewed offers, and
            manage negotiations through our software platform. You can request
            help from an agent whenever you need guidance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What kind of support will I receive when buying a home with
            Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Agents are available to assist with negotiations, offers, and any
            legal requirements, ensuring that you have expert support when
            needed.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How much does it cost to buy a home through Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint charges a flat fee of $2,500 for our services, which is
            significantly lower than the traditional percentage-based
            commission. This is typically paid by the seller just like a
            traditional commission. This fee covers all the support you&apos;ll
            need to complete your transaction.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            Can I tour homes with Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can schedule tours directly through the Homepoint platform.
            An agent will assist with the showings. This agent only assists in
            the home showing and has no other commitment than the showing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What if I already have an agent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you&apos;re interested in a Homepoint-listed property,
            you&apos;ll continue working with your agent to pursue it. For other
            listings, we&apos;d be glad to assist whenever you are not currently
            represented by another agent.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What all services are included?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul style={{ margin: 0 }}>
            <li>
              <strong>Flat Fee Structure</strong>: Pay only $2,500 at closing
              instead of a traditional percentage-based commission.
            </li>
            <li>
              <strong>Interested Homes List</strong>: Create and manage a list
              of properties you&apos;re interested in.
            </li>
            <li>
              <strong>Tour Scheduling</strong>: Easily schedule home tours
              directly through the platform for the properties on your list.
            </li>
            <li>
              <strong>Offer Preparation</strong>: Prepare and submit offers
              through the platform for homes on your list.
            </li>
            <li>
              <strong>Buying Power Advantage</strong>: Use the money saved from
              our flat fee to strengthen your offer compared to those with
              higher commission fees.
            </li>
            <li>
              <strong>Expert Guidance</strong>: An agent is available to provide
              advice on pricing, offer strategy, and negotiations.
            </li>
            <li>
              <strong>Transaction Support</strong>: All required contracts,
              disclosures, and help through the closing process.
            </li>
            <li>
              <strong>Online Dashboard</strong>: Track your buying journey, from
              scheduling tours to submitting offers and completing the sale.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion> */}
    </Container>
  );
};

export default FAQ;
