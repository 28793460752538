import { HomeOutlined } from '@mui/icons-material';
import { IRoute } from '../interfaces';
import SignUp from '../pages/Signup';
import Login from '../pages/Login';
import Password from '../pages/Password';
import RealspectorLanding from '../pages/Landings/RealspectorLanding';

export const routes: Array<IRoute> = [
  {
    key: 'login-route',
    title: 'Log In',
    path: '/login',
    enabled: true,
    component: Login,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'signup-route',
    title: 'Sign Up',
    path: '/signup',
    enabled: true,
    component: SignUp,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'password-route',
    title: 'Password',
    path: '/password',
    enabled: true,
    component: Password,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'realspector-route',
    title: 'Realspector',
    path: '/realspector',
    enabled: true,
    component: RealspectorLanding,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
];
