import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Grid, Button, Stack, TextField, InputAdornment } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InspectionsWithRelations } from '../../api/services/inspections';
import { InspectionStatus } from '@prisma/client';

interface InspectionEditFormProps {
  inspection: InspectionsWithRelations;
  onSubmit: (values: FormValues) => void;
}

interface FormValues {
  cost: number;
  inspectionDate: Date | null;
  status: InspectionStatus;
}

const InspectionEditForm: React.FC<InspectionEditFormProps> = ({
  inspection,
  onSubmit,
}) => {
  const initialValues: FormValues = {
    cost: inspection.cost ?? 0,
    inspectionDate: inspection.inspectionDate,
    status: inspection.status,
  };

  const yupSchema = yup.object({
    cost: yup.number().required('Price is required'),
    inspectionDate: yup.date().required('Inspection date is required'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: yupSchema,
  });

  const handleAcceptQuote = () => {
    formik.setFieldValue('status', 'APPROVED');
    formik.handleSubmit();
  };

  const handleRejectQuote = () => {
    formik.setFieldValue('status', 'DECLINED');
    formik.handleSubmit();
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <TextField
          name="cost"
          label="Price"
          type="number"
          fullWidth
          value={formik.values.cost}
          onChange={(e) => {
            formik.setFieldValue('cost', e.target.value);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          error={!!formik.errors.cost}
          helperText={formik.errors.cost}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Inspection Date"
            name="inspectionDate"
            sx={{ width: '100%' }}
            value={formik.values.inspectionDate}
            onChange={(date) => {
              formik.setFieldValue('inspectionDate', date);
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="success"
            onClick={handleAcceptQuote}
          >
            Accept Quote
          </Button>
          <Button variant="contained" color="error" onClick={handleRejectQuote}>
            Reject Quote
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default InspectionEditForm;
