import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import FAQ from './components/FAQ';
import WaitlistForm from './WaitlistForm';
import GuideV2 from './components/GuideV2';

const LandingV2 = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const veryMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [price, setPrice] = useState(400000);

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const id = location.hash.substring(1); // Remove the '#' character
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const quotes = [
    {
      quote: "I'm losing thousands paying these commissions selling my house.",
    },
    {
      quote:
        'Navigating the real estate process feels overwhelming and confusing.',
    },
    {
      quote:
        // eslint-disable-next-line max-len
        'We’re struggling to save enough for a down payment while competing in this market.',
    },
    { quote: 'Our offers were outbid 3 times in a week.' },
  ];

  const Quote = ({ quote, color }: { quote: string; color: string }) => (
    <Typography
      variant="body1"
      sx={{
        fontWeight: 500,
        color,
      }}
    >
      <span style={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
        &#8220;
      </span>
      {quote}
      <span style={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
        &#8221;
      </span>
    </Typography>
  );

  return (
    <>
      <Grid container spacing={2} p={mobile ? 0 : 2}>
        <Grid xs={12} sm={6}>
          <Stack direction={'column'} textAlign={mobile ? 'center' : undefined}>
            <span>
              <Stack mb={1} mt={mobile ? 0 : -1} spacing={1}>
                <Typography variant={mobile ? 'h4' : 'h3'} fontWeight={700}>
                  Take control of buying and selling your home.
                </Typography>
                <Typography variant="body1">
                  Real estate is expensive and complicated — but it doesn&apos;t
                  have to be. Homepoint is here to help.
                </Typography>
              </Stack>
              <Card sx={{ boxShadow: 3, mt: 2 }}>
                <Stack direction="row">
                  <TextField
                    label="Email"
                    InputProps={{
                      style: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        navigate(`/signup?email=${email}`);
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    sx={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      textWrap: 'nowrap',
                      minWidth: 130,
                    }}
                    onClick={() => {
                      navigate(`/signup?email=${email}`);
                    }}
                  >
                    Get Started
                  </Button>
                </Stack>
              </Card>
              <Typography variant="caption">
                No commitment required. Explore at your own pace.
              </Typography>
            </span>
          </Stack>
        </Grid>
        {!veryMobile && (
          <Grid sm={6}>
            <img
              src={'/hp_action1.jpg'}
              style={{ width: '100%', borderRadius: 8 }}
            />
          </Grid>
        )}
      </Grid>

      {/* Customer Problem */}
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid container spacing={2} textAlign={'center'}>
          <Grid xs={12}>
            <Stack direction="column" mt={0} spacing={1}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Does this sound familiar?
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12}>
            <Stack direction="row" spacing={1}>
              <Quote
                quote={quotes[0].quote}
                color={theme.palette.common.black}
              />
              <Quote
                quote={quotes[2].quote}
                color={theme.palette.common.black}
              />
            </Stack>
          </Grid>
          <Grid xs={12} justifyItems={'center'}>
            <Stack direction="row" spacing={1}>
              <Quote
                quote={quotes[3].quote}
                color={theme.palette.common.black}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <GuideV2 mobile={mobile} price={price} handlePriceChange={setPrice} />
      <Box textAlign="center" p={mobile ? 2 : 0}>
        <Button
          variant="contained"
          color="highlight"
          fullWidth
          sx={{ maxWidth: 300, mt: 2, fontWeight: 700, fontSize: '1.2rem' }}
          onClick={() => navigate('/signup')}
        >
          Get Started
        </Button>
        <br />
        <Typography variant="caption">
          No commitment required. Explore at your own pace.
        </Typography>
      </Box>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="lg" sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h4" fontWeight={600}>
          Mission
        </Typography>
        <br />
        <Typography variant="h6" fontWeight={500}>
          &#8220;Homepoint is reimagining real estate by offering buyers and
          sellers a fresh, affordable alternative. Our mission is to provide
          unmatched value, expertise, and the freedom to choose a path that fits
          their unique needs.&#8221;
        </Typography>
        <br />
        <Button
          variant="contained"
          fullWidth={mobile}
          color="highlight"
          sx={{
            minWidth: mobile ? 'auto' : 200, // Ensures button has auto width on mobile, 300px otherwise
            whiteSpace: 'nowrap',
            width: mobile ? '100%' : 200, // Ensures full width on mobile, 300px otherwise
            fontWeight: 700,
          }}
          onClick={() => navigate('/aboutus')}
        >
          About Us
        </Button>
      </Container>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <FAQ />
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="md">
        <Stack
          direction="column"
          alignItems={'center'}
          pb={2}
          textAlign={'center'}
          spacing={2}
        >
          <Typography variant="h5" fontWeight={600}>
            Not quite ready yet?
          </Typography>
          Not ready to sell or buy yet? Let&apos;s stay in touch! Provide your
          email to receive our newsletter and latest news on Homepoint.
          <WaitlistForm mobile={mobile} title="Sign Up" color="primary" />
        </Stack>
      </Container>
    </>
  );
};

export default LandingV2;
