import { Typography } from '@mui/material';
import { useEffect } from 'react';

/* eslint-disable react/no-unescaped-entities */
const Policies: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Typography variant="h5" textAlign={'center'}>
        Policies
      </Typography>
      <h3>END USER LICENSE AGREEMENT</h3>
      <p>
        This END USER LICENSE AGREEMENT (the "Agreement") is a binding agreement
        between HOMEPOINT BROKERAGE LLC., d/b/a Homepoint ("Homepoint") and the
        person or entity who utilizes the Platform ("You"). This Agreement was
        last revised and shall become effective as of 09/05/2024.
      </p>
      <h3>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THIS PLATFORM.</h3>
      <p>
        By using the platform made available on Homepoint's primary landing
        webpage (the "Platform") and any applicable documentation, media,
        manuals and other technical information appurtenant to the services
        provided by the Platform (the "Related Materials"), you hereby agree to
        the following terms and conditions.
      </p>
      <p>
        THE PLATFORM AND THE RELATED MATERIALS ARE PROVIDED TO YOU BY HOMEPOINT
        FOR USE ONLY IN ACCORDANCE WITH THE TERMS, CONDITIONS AND RESTRICTIONS
        SET OUT IN THIS AGREEMENT. ALL RIGHTS WHICH ARE NOT EXPRESSLY GRANTED TO
        YOU UNDER THIS AGREEMENT ARE RESERVED BY HOMEPOINT. YOU REPRESENT AND
        WARRANT THAT: (I) YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING
        AGREEMENT; AND (II) IF YOU ARE A CORPORATION, GOVERNMENTAL ORGANIZATION,
        OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER
        INTO THIS AGREEMENT ON BEHALF OF LICENSEE AND BIND LICENSEE TO ITS
        TERMS. THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT, CONCERNING ANY
        SOFTWARE THAT YOU DID NOT ACQUIRE LAWFULLY OR THAT IS NOT A LEGITIMATE,
        AUTHORIZED COPY OF THE PLATFORM.
      </p>
      <h3>License</h3>
      <p>
        Subject to the terms and conditions of this Agreement, Homepoint grants
        to you a revocable, limited, non-exclusive, non-transferable license,
        without the right to sublicense, to use the Platform on your devices and
        to use the Platform and the Related Materials in accordance with this
        Agreement and any other written agreement that you may have with
        Homepoint. You are being granted a right and license to use the Platform
        and the Related Materials. Homepoint does not transfer title of the
        Platform or the Related Materials to you. This Agreement is a legally
        binding agreement between Homepoint and you. If you do not agree to the
        terms and conditions of this Agreement, you are required to immediately
        cease using the Platform and any Related Materials. Homepoint reserves
        the right to terminate this Agreement and the licenses granted in the
        event that you breach any term or condition of this Agreement.
      </p>
      <h3>Distribution</h3>
      <p>
        Unless otherwise authorized herein or by a written agreement between you
        and Homepoint, the Platform, any Related Materials, and the license
        herein granted shall not be copied, shared, distributed, re-sold,
        offered for re-sale, transferred or sub-licensed in whole or in part.
      </p>
      <h3>User Agreement</h3>
      <p>
        <strong>Use Restrictions.</strong> You shall not, and shall require your
        Users not to, directly or indirectly: (i) provide any other person,
        including any subcontractor, independent contractor, affiliate, or
        service provider, with access to or use of the Platform or Related
        Materials; (ii) modify, translate, adapt, or otherwise create derivative
        works or improvements, whether or not patentable, of the Platform or
        Related Materials or any part thereof; (iii) combine the Platform or any
        part thereof with, or incorporate the Platform or any part thereof in,
        any other programs; (iv) reverse engineer, disassemble, decompile,
        decode, or otherwise attempt to derive or gain access to the source code
        of the Platform or any part thereof; (v) remove, delete, alter, or
        obscure any trademarks or any copyright, trademark, patent, or other
        intellectual property or proprietary rights notices provided on or with
        the Platform or Related Materials, including any copy thereof; (vi)
        rent, lease, lend, sell, sublicense, assign, distribute, publish,
        transfer, or otherwise make available the Platform, or any features or
        functionality of the Platform, to any third party for any reason,
        whether or not over a network or on a hosted basis, including in
        connection with the internet or any web hosting, wide area network
        (WAN), virtual private network (VPN), virtualization, time-sharing,
        service bureau, software as a service, cloud, or other technology or
        service; (vii) use the Platform and any Related Materials in violation
        of any applicable law, regulation or rule; or (viii) assign, sell,
        share, loan, rent, lease, borrow, network or transfer the use of this
        Platform. The assignment, sublicense, networking, timesharing, sale, or
        distribution of this Platform is strictly forbidden without the prior
        written consent of Homepoint.
      </p>
      <p>
        <strong>Copyright Restrictions.</strong> You acknowledge and agree that
        any and all intellectual property rights to or arising from the Platform
        and Related Materials, including any new releases, modifications,
        updates or enhancements, are and shall remain the exclusive property of
        Homepoint and/or its licensors. Nothing in this Agreement intends to
        transfer any such intellectual property rights to, or to vest any such
        intellectual property rights in, you. You are only entitled to the
        limited use of the intellectual property rights granted to you under
        this Agreement.
      </p>
      <h3>Limitation of Liability and Indemnification</h3>
      <p>
        You will indemnify, hold harmless, and defend Homepoint, its employees,
        agents and distributors against any and all claims, proceedings, demand
        and costs resulting from or in any way connected with your use of the
        Platform and the Related Materials. IN NO EVENT (INCLUDING, WITHOUT
        LIMITATION, IN THE EVENT OF NEGLIGENCE) WILL HOMEPOINT, ITS LICENSORS
        AND CONTRIBUTORS, EMPLOYEES, AGENTS, DISTRIBUTORS, OR LICENSORS BE
        LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY,
        OR PUNITIVE DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES
        FOR LOSS OF PROFITS, LOSS OF USE, BUSINESS INTERRUPTION, LOSS OF
        INFORMATION OR DATA, PERSONAL INJURY, OR PECUNIARY LOSS), IN CONNECTION
        WITH OR ARISING OUT OF OR RELATED TO THIS AGREEMENT.
      </p>
      <h3>Disclaimer and Warranties</h3>
      <p>
        THE PLATFORM, THE RELATED MATERIALS, AND ANY THIRD-PARTY SOFTWARE
        COMPONENTS, TECHNOLOGIES, OR EXTENSIONS ARE BEING PROVIDED TO YOU ON AN
        "AS IS" AND "AS AVAILABLE" BASIS. EXCEPT AS EXPRESSLY STATED IN WRITING
        BY AN AUTHORIZED EMPLOYEE OF HOMEPOINT, HOMEPOINT AND ITS LICENSORS AND
        CONTRIBUTORS MAKE NO REPRESENTATION OR WARRANTIES IN RESPECT OF THE
        PLATFORM, THE RELATED MATERIALS, OR ANY THIRD-PARTY SOFTWARE COMPONENTS,
        TECHNOLOGIES, OR EXTENSIONS AND EXPRESSLY EXCLUDES ALL OTHER WARRANTIES,
        EXPRESSED OR IMPLIED, ORAL OR WRITTEN.
      </p>
      <h3>Governing Law</h3>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the state of Alabama. You acknowledge that by using the services
        provided under this Agreement, you have transacted business in the state
        of Alabama.
      </p>
      <h3>Term and Termination</h3>
      <p>
        This Agreement will be effective from the date that you initially use
        the Platform and shall remain effective until terminated by either
        Homepoint or you. Any failure to comply with the terms and conditions of
        this Agreement will result in automatic and immediate termination of
        this Agreement and the licenses granted herein. You may terminate this
        Agreement with immediate effect at any time, with or without cause.
      </p>
      <h3>Equitable Relief</h3>
      <p>
        You agree that a material breach of this Agreement by you would cause
        irreparable injury to Homepoint for which there may be no adequate
        remedy at law.
      </p>
      <h3>No Waiver</h3>
      <p>
        No term or condition of the Agreement will be deemed amended or waived,
        and no breach excused, unless such amendment, waiver or consent is in
        writing and signed by Homepoint.
      </p>
      <h3>No Agency</h3>
      <p>
        Neither this Agreement nor use of the Platform shall create a
        partnership, joint venture, employment, or other agency relationship
        between you and Homepoint. You may not enter into any contract on
        Homepoint's behalf or bind (legally or otherwise) Homepoint in any way.
      </p>
      <h3>Saved Password Security</h3>
      <p>
        Certain features of the Platform have been developed with encryption
        technology to protect the information and data that you provide through
        the Platform. Although the Platform uses advanced encryption
        technologies, Homepoint does not guarantee or warrant that the Platform
        will prevent any unauthorized access to the information and data that
        you provide through the Platform.
      </p>
      <h3>Consent to Use of Data</h3>
      <p>
        You agree that Homepoint may directly or indirectly, collect, store and
        use information transmitted by you through the Platform, the Related
        Materials, and/or gathered in any manner as part of the services
        provided to you.
      </p>
      <h3>Collection, Storage and Use of Data</h3>
      <p>
        The transfer, processing, and storing of information collected by
        Homepoint may be within or outside the United States. You understand and
        agree that Homepoint may, upon receipt of inquiries from law enforcement
        agencies, inform such agencies and authorities of your identity.
      </p>
      <h3>Third Party Software Applications and Extensions</h3>
      <p>
        Homepoint reserves the right to advertise and recommend third-party
        software applications and software extensions to you for the purposes of
        enhancing your experience with the Platform.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        Homepoint recognizes the importance of protecting the personal
        information you provide to or through the Platform. Please read the
        Privacy Policy in this Section 8 carefully.
      </p>
      <h3>Information Homepoint Collects</h3>
      <p>
        In order to process your transactions, fulfill your requests and
        maintain Homepoint's records, we collect information from Homepoint's
        users and anyone else who uses services provided by Homepoint.
      </p>
      <h3>How Information Is Collected</h3>
      <p>
        Homepoint collects this information in different ways, including: (i)
        directly from you when you provide it to us, through use of the
        Platform; (ii) automatically as you navigate through the Platform.
      </p>
      <h3>How Information is Used</h3>
      <p>
        Homepoint uses the information it collects to improve and customize the
        Platform and its services, among other purposes.
      </p>
      <h3>Tracking Technologies</h3>
      <p>
        Homepoint uses cookies and web beacons to track activity on the
        Platform. Cookies are small files placed on your hard drive to track
        information.
      </p>
      <h3>How Information Is Shared</h3>
      <p>
        Homepoint may share personal information when required by law or valid
        legal process.
      </p>
      <h3>Security Measures</h3>
      <p>
        Homepoint follows generally accepted standards to protect the
        information it collects, but no security measures are completely
        effective.
      </p>
      <h3>Right To Opt-Out</h3>
      <p>
        Homepoint utilizes an opt-out policy for sending online notifications
        regarding services, products, and programs.
      </p>
      <h3>SMS Opt-In Clause</h3>
      <p>
        All the above categories exclude text messaging originator opt-in data
        and consent; this information will not be shared with any third parties.
      </p>
      <h3>Editing Your Information</h3>
      <p>
        You may edit your personal contact information directly in your account
        on the Platform or by contacting Homepoint.
      </p>
      <h3>Changes to Privacy Policy</h3>
      <p>
        Homepoint reserves the right to change this Policy at any time.
        Continued use of the Platform after changes constitutes your agreement
        to the updated terms.
      </p>
      <h3>Questions</h3>
      <p>
        If you have any questions regarding this Policy or Homepoint's privacy
        practices, please contact: hello@homepoint.co.
      </p>
      <h3>Changes to Terms</h3>
      <p>
        Homepoint may make changes to the terms and conditions of this Agreement
        and the Policy from time to time, and at its sole discretion.
      </p>
      <h3>Survival</h3>
      <p>
        All provisions of this Agreement which by their terms are intended to
        survive the termination of this Agreement shall survive such termination
        until fully performed.
      </p>
      <h3>Communications Policy</h3>
      <b>1. Consent to Receive Transactional Communications</b>
      <br />
      By submitting an offer through the Homepoint platform, both agents (on
      behalf of their clients) and direct buyers consent to receiving
      transactional emails and SMS communications from Homepoint. These
      communications are essential for the smooth progression of the real estate
      transaction and may include, but are not limited to:
      <ul>
        <li>Offer acceptance notifications</li>
        <li>
          Scheduling and coordination of home inspections, appraisals, and other
          key transaction milestones
        </li>
        <li>Updates on transaction status</li>
        <li>
          Closing reminders and other relevant updates pertaining to the
          property sale or purchase
        </li>
      </ul>
      <b>2. Scope of Communications</b>
      <br />
      Homepoint will strictly send communications related to the transaction in
      question, ensuring that no promotional or marketing content is included
      unless explicitly requested or consented to separately. These
      communications are essential for keeping all parties informed and ensuring
      the transaction progresses without delays.
      <br />
      <br />
      <b>3. Responsibility of the Agent or Direct Buyer</b>
      <br />
      In the case of a transaction involving an agent, the agent is responsible
      for informing their clients about this policy and obtaining the necessary
      consent for Homepoint to send transactional communications. Direct buyers
      submitting offers without an agent also agree to receive these
      communications to ensure timely updates on their transaction.
      <br />
      <br />
      <b>4. Opting Out of Transactional Communications</b>
      <br />
      Opting out of transactional communications is not possible during an
      active transaction, as these communications are necessary to facilitate
      the completion of the sale. Agents and buyers understand that receiving
      these updates is essential for ensuring that critical steps in the
      transaction are completed on time.
      <br />
      <br />
      <b>5. Data Privacy and Protection</b>
      <br />
      All personal data collected for transactional communications, including
      email addresses and phone numbers, will be handled in accordance with
      Homepoint&apos;s Privacy Policy and applicable data protection laws.
      Homepoint will use this information solely for the purposes outlined in
      this policy and will ensure that it remains confidential and secure.
    </div>
  );
};

export default Policies;
