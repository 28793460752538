import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

const showingsService = {
  find: async () => {
    const res = await axiosInstance.get('/showings');
    return res.data;
  },
  post: async (
    data: Prisma.showingsCreateWithoutUserInput,
    isTour = false,
    notify = true
  ) => {
    const res = await axiosInstance.post('/showings', data, {
      params: {
        isTour,
        notify,
      },
    });
    return res.data;
  },
  patch: async (id: string, data: Prisma.showingsUpdateInput) => {
    const res = await axiosInstance.patch(`/showings/${id}`, data);
    return res.data;
  },
};

export default showingsService;
