import axiosInstance from '../api';
import { Prisma, comps, listings, pricingOpinions } from '@prisma/client';

export type listingsWithOpenHouses = Prisma.listingsGetPayload<{
  include: { openHouses: true };
}>;

export type listingsWithUploads = Prisma.listingsGetPayload<{
  include: { uploads: true };
}>;

export type listingsWithExtra = listingsWithUploads &
  listingsWithOpenHouses & {
    comps: comps[];
    estimatedPrice: number;
  };

export interface listingsWithPricingOpinion extends listingsWithExtra {
  pricingOpinion: pricingOpinions;
}

interface PatchListingParams {
  id: string;
  data: Partial<listings>;
  notify?: boolean;
}

const listingsService = {
  find: async ({
    params,
  }: {
    params?: Prisma.listingsFindManyArgs;
  }): Promise<listingsWithUploads[]> => {
    const response = await axiosInstance.get('/listings', { params });
    return response.data;
  },
  get: async ({
    id,
    params,
  }: {
    id: string;
    params?: any;
  }): Promise<listingsWithExtra> => {
    const response = await axiosInstance.get(`/listings/${id}`, { params });
    return response.data;
  },
  patch: async ({
    id,
    data,
    notify = false,
  }: PatchListingParams): Promise<listings> => {
    const response = await axiosInstance.patch(`/listings/${id}`, data, {
      params: { notify },
    });
    return response.data;
  },
  create: async (refId: string, buyer = false) => {
    const response = await axiosInstance.post(
      '/listings',
      { refId },
      { params: { buyer } }
    );
    return response.data;
  },
};

export default listingsService;
